<template>
  <v-card>
    <v-snackbar v-model="showSnackbarErr" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
      {{ errMessage }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-title>
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <span class="text--white text-h4 font-weight-black">Campaign Tracker</span>
        </v-col>
        <v-col class="text-end" cols="12" lg="6" md="6" sm="12" xs="12">
          <v-btn color="primary" @click="createLink">
            <v-icon color="white" left>
              {{ icons.mdiPlus }}
            </v-icon>
            <span class="white--text">Create Campaign</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <span class="text-subtitle-1 font-weight-regular"
        >Create custom links, and make it easier for your team to handle customers</span
      >
    </v-card-subtitle>

    <v-card-text>
      <div class="mb-2 mt-6">
        <span class="text-h6 font-weight-semibold text--white"> Campaign History </span>
      </div>
      <v-row :style="$vuetify.breakpoint.xs ? '' : `margin-bottom:-24px`">
        <v-col cols="12" md="3">
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Search Campaign"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="filterStatus"
            :items="filterItems"
            label="Filter"
            outlined
            dense
            @change="filterCampaign"
          ></v-select>
        </v-col>
      </v-row>
      <!-- <pre>{{campaignList}}</pre> -->
      <v-snackbar v-model="showSnackbar" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
        Contact List has been deleted
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="showSnackbar = false">
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
      <v-data-table
        v-model="selectedCampaign"
        item-key="id"
        :headers="headers"
        :items="campaignList"
        :search="search"
        :items-per-page="10"
        :loading="loadingTable"
        loading-text="Loading... Please wait"
        multi-sort
        return-object
        class="table-broadcast"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
          'items-per-page-options': [5, 10, 30, 50, 100],
        }"
        checkbox-color="#111B21"
      >
        <template v-slot:header.data-table-select="{ on, props }">
          <div class="d-flex mx-auto">
            <v-simple-checkbox :ripple="false" color="#111B21" v-bind="props" v-on="on"></v-simple-checkbox>
          </div>
        </template>
        <template #[`item.activeLink`]="{ item }">
          <span>{{ item.campaign_tracker.length }}</span>
        </template>
        <template #[`item.contacts`]="{ item }">
          <span>{{ item.contact_list.length }}</span>
        </template>
        <template #[`item.action`]="{ item }">
          <v-btn color="primary" class="me-3" @click="editCampaign(item)"> Edit Campaign </v-btn>

          <v-btn color="primary" @click="goToContacts(item.contact_list_id)"> See Contact </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="dialogAddCampaign" width="800px" persistent>
      <v-card>
        <v-card-title>
          <span class="text--white text-h5 font-weight-black">Add Link</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-snackbar v-model="showSnackbarErr" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
            {{ errMessage }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <!-- <pre>{{ campaignId }}</pre> -->
          <v-dialog v-model="dialogAddCampaignLink" width="800px">
            <v-card>
              <v-snackbar
                v-model="showSnackbarErr"
                color="error"
                :timeout="2000"
                :top="true"
                style="z-index: 5 !important"
              >
                {{ errMessage }}
                <template #action="{ attrs }">
                  <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
                    {{ $t('close') }}
                  </v-btn>
                </template>
              </v-snackbar>
              <v-card-title>
                <span class="text--white text-h5 font-weight-black">Add Campaign Link</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row class="mb-n8">
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex">
                    <p class="order-text me-3 mt-2">Name * <span class="mobile-col-show ms-3"> : </span></p>
                  </v-col>
                  <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                    <span class="order-text me-3 mt-0 mobile-span text-area-span"> : </span>
                    <v-text-field
                      v-model="campaignLink.name"
                      outlined
                      hide-details
                      required
                      :label="`Name`"
                      class="mb-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex">
                    <p class="order-text me-3 mt-2">Code *<span class="mobile-col-show ms-3"> : </span></p>
                  </v-col>
                  <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                    <span class="order-text me-3 mt-0 mobile-span text-area-span"> : </span>
                    <v-text-field v-model="input" outlined hide-details required :label="`Code`" dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex"> </v-col>
                  <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                    <div v-if="slugAvailability && input !== ''" class="ml-3 mb-2">
                      <span>{{ $t('LinkGenerator.slugIsAvailability') }} </span>
                      <v-icon color="primary">
                        {{ icons.mdiCheckCircle }}
                      </v-icon>
                    </div>
                    <div v-if="!slugAvailability && input !== ''" class="ml-3 mb-2">
                      <span>{{ $t('LinkGenerator.slugIsNotAvailability') }} </span>
                      <v-icon color="error">
                        {{ icons.mdiCloseCircle }}
                      </v-icon>
                    </div>
                  </v-col>
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex">
                    <p class="order-text me-3 mt-2">message<span class="mobile-col-show ms-3"> : </span></p>
                  </v-col>
                  <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                    <span class="order-text me-3 mb-3 mobile-span text-area-span"> : </span>
                    <v-textarea
                      v-model="campaignLink.message"
                      outlined
                      name="input-7-4"
                      label="Outlined textarea"
                      value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="#ffeeee" @click="closeDialogLink">
                  <span class="error--text">{{ $t('cancel') }}</span>
                </v-btn>

                <v-btn color="success" @click="saveCampaignLink">
                  {{ $t('EOrdering.save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogEditCampaignLink" width="800px">
            <v-card>
              <v-snackbar
                v-model="showSnackbarErr"
                color="error"
                :timeout="2000"
                :top="true"
                style="z-index: 5 !important"
              >
                {{ errMessage }}
                <template #action="{ attrs }">
                  <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
                    {{ $t('close') }}
                  </v-btn>
                </template>
              </v-snackbar>
              <v-card-title>
                <span class="text--white text-h5 font-weight-black">Edit Campaign Link</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row class="mb-n8">
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex">
                    <p class="order-text me-3 mt-2">Name * <span class="mobile-col-show ms-3"> : </span></p>
                  </v-col>
                  <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                    <span class="order-text me-3 mt-0 mobile-span text-area-span"> : </span>
                    <v-text-field
                      v-model="campaignLink.name"
                      disabled
                      outlined
                      hide-details
                      required
                      :label="`Name`"
                      class="mb-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex">
                    <p class="order-text me-3 mt-2">Code *<span class="mobile-col-show ms-3"> : </span></p>
                  </v-col>
                  <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                    <span class="order-text me-3 mt-0 mobile-span text-area-span"> : </span>
                    <v-text-field
                      v-model="input"
                      disabled
                      outlined
                      hide-details
                      required
                      :label="`Code`"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex"> </v-col>
                  <!-- <v-col
                  cols="12"
                  md="7"
                  sm="8"
                  class="d-flex col-margin-top-mobile"
                >
                  <div v-if="slugAvailability && input !== ''" class="ml-3 mb-2">
                    <span>{{ $t('LinkGenerator.slugIsAvailability') }} </span>
                    <v-icon color="primary">
                      {{ icons.mdiCheckCircle }}
                    </v-icon>
                  </div>
                  <div v-if="!slugAvailability && input !== ''" class="ml-3 mb-2">
                    <span>{{ $t('LinkGenerator.slugIsNotAvailability') }} </span>
                    <v-icon color="error">
                      {{ icons.mdiCloseCircle }}
                    </v-icon>
                  </div>
                </v-col> -->
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex">
                    <p class="order-text me-3 mt-2">message<span class="mobile-col-show ms-3"> : </span></p>
                  </v-col>
                  <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                    <span class="order-text me-3 mb-3 mobile-span text-area-span"> : </span>
                    <v-textarea
                      v-model="campaignLink.message"
                      outlined
                      name="input-7-4"
                      label="Outlined textarea"
                      value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="#ffeeee" @click="closeDialogEditLink">
                  <span class="error--text">{{ $t('cancel') }}</span>
                </v-btn>

                <v-btn color="success" @click="saveEditCampaignLink(campaignLink)">
                  {{ $t('EOrdering.save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-row class="mb-n8">
            <v-col cols="12" md="1" class="flex mobile-col"></v-col>
            <v-col cols="12" md="3" sm="4" class="flex">
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.selectShop') }} *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
              <span class="order-text me-3 mb-2 mobile-span"> : </span>
              <!-- <pre>{{chatbotData.selectedShop}}</pre> -->
              <!-- note items musbe list Shop -->
              <v-select
                v-model="campaignData.selectedInstance"
                :items="instances"
                item-text="label"
                return-object
                outlined
                :label="$t('channels.channel')"
                hide-details
                required
                class="mb-3"
                dense
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" md="1" class="flex mobile-col"></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="1" class="flex mobile-col"></v-col>
            <v-col cols="12" md="3" sm="4" class="flex">
              <p class="order-text me-3 mt-2">Campaign Name<span class="mobile-col-show ms-3"> : </span></p>
            </v-col>
            <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
              <span class="order-text me-3 mt-n1 mobile-span text-area-span"> : </span>
              <v-text-field
                v-model="campaignData.campaignName"
                outlined
                hide-details
                required
                :label="`Campaign Name`"
                class="mb-2"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="mobile-col"> </v-col>
          </v-row>

          <v-row class="mt-8">
            <v-col cols="12" class="text-end">
              <div class="additional-faq mx-auto">
                <v-btn class="text-capitalize" x-small @click="AddNewCampaignLink">
                  <v-icon color="primary" class="me-1">
                    {{ icons.mdiPlusCircleOutline }}
                  </v-icon>
                  <span class="text-subtitle-1 font-weight-regular primary--text">{{ $t('add') }} New Link</span>
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" class="mt-n1 ms-1" v-on="on">
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <v-card> test </v-card>
                </v-menu>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="1" class="flex mobile-col"></v-col>
            <v-col
              v-if="campaignData.link"
              cols="12"
              md="10"
              sm="12"
              class="d-flex justify-center col-margin-top-mobile"
            >
              <v-data-table
                item-key="id"
                :headers="linkHeaders"
                :items="campaignData.link"
                :items-per-page="10"
                :loading="loadingTable"
                loading-text="Loading... Please wait"
                multi-sort
                return-object
                class="table-broadcast"
                :footer-props="{
                  'items-per-page-text': $t('rowsPerPageLabel'),
                  'items-per-page-options': [5, 10, 30, 50, 100],
                }"
                checkbox-color="#111B21"
              >
                <template v-slot:header.data-table-select="{ on, props }">
                  <div class="d-flex mx-auto">
                    <v-simple-checkbox :ripple="false" color="#111B21" v-bind="props" v-on="on"></v-simple-checkbox>
                  </div>
                </template>

                <template #[`item.link`]="{ item }">
                  <!-- <div class="truncate">{{ item.name}}</div> -->
                  <a :href="item.redirect_url" target="_blank" class="truncate">{{ item.link }}</a>
                  <!-- <v-btn icon @click="copyURL(item.redirect_url)">
                    <v-icon>
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </v-btn> -->
                </template>
                <!-- <a :href="item.redirect_url" target="_blank" class="text-decoration-none">{{ item.url }}</a> -->
                <template #[`item.action`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on" @click="editMessage(item)">
                        <v-icon>{{ icons.mdiPencil }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('edit') }} Link</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on" @click="copyURL(item.link)">
                        <v-icon>{{ icons.mdiContentCopy }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('copy') }} Link</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on" @click="openDialogDeleteCampaignLink(item)">
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('delete') }} Link</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn v-if="!isModify" color="#ffeeee" @click="closeDialog">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>

          <v-btn color="success" @click="saveCampaign">
            {{ $t('EOrdering.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditCampaign" width="800px" persistent>
      <v-card>
        <v-dialog v-model="openDeleteSlugDialog" width="600px">
          <v-card>
            <v-card-title class="mobile-header">
              <div class="text-h5">{{ $t('delete') }} Link</div>
            </v-card-title>
            <v-divider />
            <v-card elevation="0">
              <!-- <v-card-title class="align-start">
            <span class="text-lg font-weight-semibold">Select Link</span>
            <v-icon>
              {{ icons.mdiLink }}
            </v-icon>
          </v-card-title> -->
              <!-- <v-card-text>
            <v-select
              v-model="selectedSlug"
              :items="currentSlug"
              item-text="slug"
              item-value="slug"
              attach
              outlined
              :prepend-inner-icon="icons.mdiLinkBoxOutline"
              dense
              label="Choose Link"
              @change="setSelectedSlug"
            />
          </v-card-text> -->
              <v-card-text>
                <h3>{{ $t('EOrdering.deleteConfirm') }}</h3>
              </v-card-text>
            </v-card>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="openDeleteSlugDialog = false">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn color="success" @click="deleteCampaignLink(deletePayload)">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar v-model="showSnackbarErr" color="error" :timeout="2000" :top="true" style="z-index: 5 !important">
          {{ errMessage }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="showSnackbarCopiedSuccessfully"
          color="success"
          :timeout="2000"
          :top="true"
          style="z-index: 5 !important"
        >
          {{ $t('LinkGenerator.copySuccess') }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeShowSnackbarCopiedSuccessfully()">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <!-- Snackbar: Copy not supported -->
        <v-snackbar
          v-model="showSnackbarCopyNotSupported"
          color="error"
          :timeout="2000"
          :top="true"
          style="z-index: 5 !important"
        >
          {{ $t('LinkGenerator.copyUnsupported') }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeShowSnackbarCopyNotSupported()">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-dialog v-model="dialogEditCampaignLink" width="800px">
          <v-card>
            <v-snackbar
              v-model="showSnackbarErr"
              color="error"
              :timeout="2000"
              :top="true"
              style="z-index: 5 !important"
            >
              {{ errMessage }}
              <template #action="{ attrs }">
                <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
                  {{ $t('close') }}
                </v-btn>
              </template>
            </v-snackbar>
            <v-card-title>
              <span class="text--white text-h5 font-weight-black">Edit Campaign Link</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="mb-n8">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">Name * <span class="mobile-col-show ms-3"> : </span></p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mt-0 mobile-span text-area-span"> : </span>
                  <v-text-field
                    v-model="campaignLink.name"
                    disabled
                    outlined
                    hide-details
                    required
                    :label="`Name`"
                    class="mb-2"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">Code *<span class="mobile-col-show ms-3"> : </span></p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mt-0 mobile-span text-area-span"> : </span>
                  <v-text-field
                    v-model="campaignLink.code"
                    outlined
                    hide-details
                    required
                    :label="`Code`"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex"> </v-col>
                <!-- <v-col
                  cols="12"
                  md="7"
                  sm="8"
                  class="d-flex col-margin-top-mobile"
                >
                  <div v-if="slugAvailability && input !== ''" class="ml-3 mb-2">
                    <span>{{ $t('LinkGenerator.slugIsAvailability') }} </span>
                    <v-icon color="primary">
                      {{ icons.mdiCheckCircle }}
                    </v-icon>
                  </div>
                  <div v-if="!slugAvailability && input !== ''" class="ml-3 mb-2">
                    <span>{{ $t('LinkGenerator.slugIsNotAvailability') }} </span>
                    <v-icon color="error">
                      {{ icons.mdiCloseCircle }}
                    </v-icon>
                  </div>
                </v-col> -->
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">message<span class="mobile-col-show ms-3"> : </span></p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mb-3 mobile-span text-area-span"> : </span>
                  <v-textarea
                    id="messageText"
                    v-model="campaignLink.message"
                    outlined
                    name="input-7-4"
                    label="Outlined textarea"
                    value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
                <!-- <v-col>
                  <emoji-picker
                    style="width: 100%"
                    :class="$vuetify.theme.isDark ? 'dark' : 'light'"
                    @emoji-click="
                      e => {
                        addEmoji(e)
                      }
                    "
                  ></emoji-picker>
                </v-col> -->
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeDialogEditLink">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn color="success" @click="saveEditCampaignLink(campaignLink)">
                {{ $t('EOrdering.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <pre>{{ campaignId }}</pre> -->

        <v-dialog v-model="dialogAddCampaignLink" width="800px">
          <v-card>
            <v-snackbar
              v-model="showSnackbarErr"
              color="error"
              :timeout="2000"
              :top="true"
              style="z-index: 5 !important"
            >
              {{ errMessage }}
              <template #action="{ attrs }">
                <v-btn text v-bind="attrs" @click="closeShowSnackbarErr()">
                  {{ $t('close') }}
                </v-btn>
              </template>
            </v-snackbar>
            <v-card-title>
              <span class="text--white text-h5 font-weight-black">Add Campaign Link</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="mb-n8">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">Name * <span class="mobile-col-show ms-3"> : </span></p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mt-0 mobile-span text-area-span"> : </span>
                  <v-text-field
                    v-model="campaignLink.name"
                    outlined
                    hide-details
                    required
                    :label="`Name`"
                    class="mb-2"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">Code *<span class="mobile-col-show ms-3"> : </span></p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mt-0 mobile-span text-area-span"> : </span>
                  <v-text-field v-model="input" outlined hide-details required :label="`Code`" dense></v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex"> </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <div v-if="slugAvailability && input !== ''" class="ml-3 mb-2">
                    <span>{{ $t('LinkGenerator.slugIsAvailability') }} </span>
                    <v-icon color="primary">
                      {{ icons.mdiCheckCircle }}
                    </v-icon>
                  </div>
                  <div v-if="!slugAvailability && input !== ''" class="ml-3 mb-2">
                    <span>{{ $t('LinkGenerator.slugIsNotAvailability') }} </span>
                    <v-icon color="error">
                      {{ icons.mdiCloseCircle }}
                    </v-icon>
                  </div>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">message<span class="mobile-col-show ms-3"> : </span></p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mb-3 mobile-span text-area-span"> : </span>
                  <v-textarea
                    v-model="campaignLink.message"
                    outlined
                    name="input-7-4"
                    label="Outlined textarea"
                    value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="#ffeeee" @click="closeDialogLink">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>

              <v-btn color="success" @click="saveCampaignLink">
                {{ $t('EOrdering.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card-title>
          <span class="text--white text-h5 font-weight-black">{{ campaignData.campaignName }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- <pre>{{campaignData}}</pre> -->
          <v-row class="mb-n8">
            <v-col cols="12" md="1" class="flex mobile-col"></v-col>
            <v-col cols="12" md="3" sm="4" class="flex">
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.selectShop') }} *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
              <span class="order-text me-3 mb-0 mobile-span"> : </span>
              <!-- <pre>{{chatbotData.selectedShop}}</pre> -->
              <!-- note items musbe list Shop -->
              <v-select
                v-model="campaignData.selectedInstance"
                :items="instances"
                item-text="label"
                return-object
                outlined
                :label="$t('channels.channel')"
                hide-details
                required
                class="mb-3"
                dense
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" md="1" class="flex mobile-col"></v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="1" class="flex mobile-col"></v-col>
            <v-col cols="12" md="3" sm="4" class="flex">
              <p class="order-text me-3 mt-2">Campaign Name<span class="mobile-col-show ms-3"> : </span></p>
            </v-col>
            <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
              <span class="order-text me-3 mt-n1 mobile-span text-area-span"> : </span>
              <v-text-field
                v-model="campaignData.campaignName"
                outlined
                hide-details
                required
                :label="`Campaign Name`"
                class="mb-2"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="mobile-col"> </v-col>
          </v-row>

          <v-row class="mt-8">
            <v-col cols="12" class="text-end">
              <div class="additional-faq mx-auto">
                <v-btn class="text-capitalize" x-small @click="AddNewCampaignLink">
                  <v-icon color="primary" class="me-1">
                    {{ icons.mdiPlusCircleOutline }}
                  </v-icon>
                  <span class="text-subtitle-1 font-weight-regular primary--text">{{ $t('add') }} New Link</span>
                </v-btn>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" class="mt-n1 ms-1" v-on="on">
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <v-card> test </v-card>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <!-- <pre>{{campaignData.link}}</pre> -->
          <!-- <pre>{{campaignData}}</pre> -->
          <v-row>
            <v-col cols="12" md="1" class="flex mobile-col"></v-col>
            <v-col
              v-if="campaignData.link"
              cols="12"
              md="10"
              sm="12"
              class="d-flex justify-center col-margin-top-mobile"
            >
              <v-data-table
                item-key="id"
                :headers="linkHeaders"
                :items="campaignData.link"
                :items-per-page="10"
                :loading="loadingTable"
                loading-text="Loading... Please wait"
                multi-sort
                return-object
                class="table-broadcast"
                :footer-props="{
                  'items-per-page-text': $t('rowsPerPageLabel'),
                  'items-per-page-options': [5, 10, 30, 50, 100],
                }"
                checkbox-color="#111B21"
              >
                <template v-slot:header.data-table-select="{ on, props }">
                  <div class="d-flex mx-auto">
                    <v-simple-checkbox :ripple="false" color="#111B21" v-bind="props" v-on="on"></v-simple-checkbox>
                  </div>
                </template>
                <template #[`item.link`]="{ item }">
                  <!-- <div class="truncate">{{ item.name}}</div> -->
                  <a :href="item.redirect_url" class="truncate">{{ item.link }}</a>
                  <!-- <v-btn icon @click="copyURL(item.redirect_url)">
                    <v-icon>
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </v-btn> -->
                </template>
                <template #[`item.action`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on" @click="editMessage(item)">
                        <v-icon>{{ icons.mdiPencil }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('edit') }} Link</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on" @click="copyURL(item.link)">
                        <v-icon>{{ icons.mdiContentCopy }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('copy') }} Link</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on" @click="openDialogDeleteCampaignLink(item)">
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('delete') }} Link</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn v-if="!isModify" color="#ffeeee" @click="closeDialog">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>

          <v-btn color="success" @click="saveCampaign">
            {{ $t('EOrdering.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiInformation,
  mdiMagnify,
  mdiPencil,
  mdiPlus,
  mdiPlusCircleOutline,
} from '@mdi/js'
import 'emoji-picker-element'
import insertTextAtCursor from 'insert-text-at-cursor'

export default {
  name: 'CampaignTracker',
  data() {
    return {
      showSnackbar: false,
      openDeleteSlugDialog: false,
      slugAvailability: false,
      errMessage: '',
      showSnackbarErr: false,
      isModify: false,
      campaignList: [],
      campaignListOrigin: [],
      dialogAddCampaignLink: false,

      // filterItems: ['All', 'Done', 'Processing', 'Waiting', 'Draft'],
      filterStatus: '',
      search: '',
      icons: {
        mdiPlus,
        mdiInformation,
        mdiPlusCircleOutline,
        mdiCloseCircle,
        mdiPencil,
        mdiDeleteOutline,
        mdiMagnify,
        mdiContentCopy,
        mdiCheckCircle,
      },
      deletePayload: null,
      timeout: null,
      selectedCampaign: null,
      loadingTable: false,
      dialogAddCampaign: false,
      dialogEditCampaign: false,
      campaignLink: {
        name: '',
        code: '',
        message: '',
      },
      campaignData: {
        selectedInstance: null,
        campaignName: '',
        link: [],
      },
      showSnackbarCopyNotSupported: false,
      showSnackbarCopiedSuccessfully: false,
      editArrLengthZero: false,
      dialogEditCampaignLink: false,
      instances: [],
    }
  },
  computed: {
    filterItems() {
      //console.log('🚀 ~ file: index.vue ~ line 1726 ~ filterItems ~ this.campaignList', this.campaignList)

      return this.campaignList.map(campaign => campaign.campaign_name)
    },
    input: {
      get() {
        return this.campaignLink.code
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.campaignLink.code = val
          this.checkAvailability()
        }, 2000)
      },
    },
    // instances() {
    //   const listInstance = []
    //   if (this.$store.getters['auth/getListInstance'].length !== 0) {
    //     this.$store.getters['auth/getListInstance'].value.forEach(instance => {
    //       listInstance.push({
    //         label: `${
    //           instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
    //             ? instance.label_server
    //             : instance.label
    //         } - (${instance.phone_number})`,
    //         value: instance.phone_number,
    //         instance: instance._id,
    //       })
    //     })
    //   }

    //   return listInstance
    // },

    linkHeaders() {
      return [
        {
          text: 'Name',
          value: 'name',
          align: 'center',
        },
        {
          text: 'Code',
          value: 'code',
          align: 'center',
        },
        {
          text: 'Link',
          value: 'link',
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
        },
      ]
    },
    headers() {
      return [
        {
          text: 'Campaign Name',
          value: 'campaign_name',
          align: 'center',
        },
        {
          text: 'Channel',
          value: 'instance',
          align: 'center',
        },
        {
          text: 'Active Link',
          value: 'activeLink',
          align: 'center',
        },
        {
          text: 'Number Of Contacts',
          value: 'contacts',
          align: 'center',
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
        },
      ]
    },

    user() {
      return this.$store.getters['auth/getUser']
    },
    campaignId() {
      return this.$store.getters['link/getCampaignId']
    },
  },
  async mounted() {
    const res = await this.$store.dispatch('link/fetchListCampaign', { sub_id: this.user.sub_id })
    const listInstance = []
    const instances = await this.$store.dispatch('auth/getAllInstances')
    if (instances.length !== 0) {
      instances.value.forEach(instance => {
        listInstance.push({
          label: `${
            instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
              ? instance.label_server
              : instance.label
          } - (${instance.phone_number})`,
          value: instance.phone_number,
          instance: instance._id,
        })
      })
    }
    this.instances = listInstance
    this.campaignList = res.data
    this.campaignListOrigin = res.data
  },
  methods: {
    filterCampaign() {
      //console.log('🚀 ~ file: index.vue ~ line 1833 ~ filterCampaign ~ this.campaignList', this.campaignList)
      // this.campaignList = this.campaignList.filter(campaign => {
      // return campaign.campaign
      // })
    },
    addEmoji(event) {
      insertTextAtCursor(document.querySelector('#messageText'), event.detail.unicode)
    },

    // campaignLink
    editMessage(link) {
      // {
      //     "chat_text": "asasas",
      //     "link": "https://wa.marketa.id/22121xaz",
      //     "code": "22121xaz",
      //     "name": "1111"
      // }
      //console.log(link, 'ini links')
      this.campaignLink = {
        name: link.name,
        code: link.code,
        message: link.chat_text,
      }
      this.dialogEditCampaignLink = true
      //console.log(link)

      // this.campaignLink
    },
    async goToContacts(item) {
      //console.log(item, 'item contac')
      const data = await this.$store.dispatch('eOrdering/getContactListCheck', { contact: item })
      //console.log(data, 'ini data bro')
      if (data.status) {
        this.$router.push({ name: 'contacts-list-custom', params: { idlist: item } })
      } else {
        this.showSnackbar = true
      }
    },
    openDialogDeleteCampaignLink(data) {
      this.deletePayload = data
      this.openDeleteSlugDialog = true
    },
    async checkAvailability() {
      //console.log('masuk slug')
      if (
        this.campaignLink.code !== '' &&
        !this.campaignLink.code.match(/[ ]/g) &&
        // eslint-disable-next-line no-useless-escape
        !this.campaignLink.code.match(/[!@#$%^&*()+=`{}\[\]:";'<>?,.\/]/g)
      ) {
        const Availability = await this.$store.dispatch('link/checkCampaignLink', {
          code: this.campaignLink.code,
          sub_id: this.user.sub_id,
        })
        //console.log(Availability, 'ini availibility')
        this.slugAvailability = Availability.data
      } else if (
        this.slugAvailability &&
        (this.campaignLink.code.match(/[ ]/g) ||
          // eslint-disable-next-line no-useless-escape
          this.campaignLink.code.match(/[!@#$%^&*()+=`{}\[\]:";'<>?,.\/]/g))
      ) {
        this.slugAvailability = false
      }
    },
    closeShowSnackbarErr() {
      this.showSnackbarErr = false
    },
    closeShowSnackbarCopyNotSupported() {
      this.showSnackbarCopyNotSupported = false
    },
    closeShowSnackbarCopiedSuccessfully() {
      this.showSnackbarCopiedSuccessfully = false
    },
    async copyURL(Url) {
      try {
        await navigator.clipboard.writeText(Url)
        this.showSnackbarCopiedSuccessfully = true
      } catch ($e) {
        this.showSnackbarCopyNotSupported = true
      }
    },
    AddNewCampaignLink() {
      if (!this.campaignData.selectedInstance || !this.campaignData.campaignName) {
        this.errMessage = 'Please select the channel and add campaign name first'
        this.showSnackbarErr = true
      } else {
        this.dialogAddCampaignLink = true
      }
    },
    async deleteCampaignLink(data) {
      await this.$store.dispatch('link/updateCampaignLink', {
        sub_id: this.user.sub_id,
        phone_number: 'e',
        instance: 'e',
        campaignId: this.campaignId,
        code: data.code,
        deleteType: 'one_campaign',
      })
      this.deletePayload = null
      this.openDeleteSlugDialog = false

      if (this.campaignData.link.length === 1) {
        this.editArrLengthZero = true
      }
      const arr = []
      this.campaignData.link.forEach(el => {
        if (el.code !== data.code) {
          arr.push(el)
        }
      })
      this.campaignData.link = arr
      this.isModify = true
      const res = await this.$store.dispatch('link/fetchListCampaign', { sub_id: this.user.sub_id })

      this.campaignList = res.data
    },
    async saveEditCampaignLink(data) {
      if (!this.campaignLink.code || !this.campaignLink.name || !this.campaignLink.message) {
        this.errMessage = 'Please fill all field'
        this.showSnackbarErr = true
      } else if (this.campaignLink.code && this.campaignLink.name && this.campaignLink.message) {
        // await this.$store.dispatch('link/updateCampaignLink', {
        //   sub_id: this.user.sub_id,
        //   phone_number: 'e',
        //   instance: 'e',
        //   campaignId: this.campaignId,
        //   code: data.code,
        //   deleteType: 'one_campaign',
        // })
        // //console.log(this.campaignData, data, this.campaignLink,  '<<<INI DIA')
        // await this.$store.dispatch('link/updateCampaignLink', {
        //   sub_id: this.user.sub_id,
        //   phone_number: this.campaignData.selectedInstance.value,
        //   instance: this.campaignData.selectedInstance.instance,
        //   campaignId: this.campaignId,
        //   campaign_name: this.campaignData.campaignName,
        //   code_name: this.campaignLink.name,
        //   chat_text: this.campaignLink.message,
        //   code: this.campaignLink.code,
        //   deleteType: 'add_one',
        // })
        await this.$store.dispatch('link/updateCampaignLink', {
          sub_id: this.user.sub_id,
          phone_number: this.campaignData.selectedInstance.value,
          instance: this.campaignData.selectedInstance.instance,
          campaignId: this.campaignId,
          campaign_name: this.campaignData.campaignName,
          code_name: this.campaignLink.name,
          chat_text: this.campaignLink.message,
          code: this.campaignLink.code,
          deleteType: 'none',
        })

        // find dl campaign
        const index = this.campaignData.link.findIndex(el => el.name === data.name)
        if (index !== -1) {
          this.campaignData.link[index].code = this.campaignLink.code
          this.campaignData.link[index].link = `${process.env.VUE_APP_SHOOTER_LINK}/${this.campaignLink.code}`
          this.campaignData.link[index].message = this.campaignLink.message
          this.campaignData.link[index].chat_text = this.campaignLink.message
        }
        //console.log(this.input, 'ini inpyut')
        this.dialogEditCampaignLink = false
        this.campaignLink = {
          name: '',
          code: '',
          message: '',
        }

        const res = await this.$store.dispatch('link/fetchListCampaign', { sub_id: this.user.sub_id })
        this.campaignList = JSON.parse(JSON.stringify(res.data))
      }
    },
    async saveCampaignLink() {
      if (!this.campaignLink.code || !this.campaignLink.name || !this.campaignLink.message || !this.slugAvailability) {
        this.errMessage = 'Please fill all field and Slug avability must be Green'
        this.showSnackbarErr = true
      } else if (
        this.dialogAddCampaign === true &&
        this.campaignLink.code &&
        this.campaignLink.name &&
        this.campaignLink.message
      ) {
        this.isModify = true
        //console.log(this.campaignData)
        const res = await this.$store.dispatch('link/updateCampaignLink', {
          sub_id: this.user.sub_id,
          phone_number: this.campaignData.selectedInstance.value,
          instance: this.campaignData.selectedInstance.instance,
          campaignId: this.campaignId,
          campaign_name: this.campaignData.campaignName,
          code_name: this.campaignLink.name,
          chat_text: this.campaignLink.message,
          code: this.campaignLink.code,
          deleteType: 'add_one',
        })
        const link = `${process.env.VUE_APP_SHOOTER_LINK}/${this.campaignLink.code}`
        const obj = {
          chat_text: this.campaignLink.message,
          link,
          code: this.campaignLink.code,
          name: this.campaignLink.name,
        }

        // https://api.whatsapp.com/send/?phone=628119885323&text=%23TLM1-1079%20Halo%20The%20Leaf%20Mansion!%0A%0ASaya%20tertarik%20dengan%20perumahan%20ini.%20Boleh%20informasikan%20detail%20terkait%20perumahan%20ini%3F%20Terima%20kasih%20%F0%9F%98%8A&app_absent=00
        this.campaignData.link.push(obj)
        this.campaignLink = {
          name: '',
          code: '',
          message: '',
        }
        this.closeDialogLink()
      } else if (
        this.dialogEditCampaign === true &&
        this.campaignLink.code &&
        this.campaignLink.name &&
        this.campaignLink.message &&
        this.editArrLengthZero === false
      ) {
        //console.log('masukd 1')
        this.isModify = true
        const res = await this.$store.dispatch('link/updateCampaignLink', {
          sub_id: this.user.sub_id,
          phone_number: this.campaignData.selectedInstance.value,
          instance: this.campaignData.selectedInstance.instance,
          campaignId: this.campaignId,
          campaign_name: this.campaignData.campaignName,
          code_name: this.campaignLink.name,
          chat_text: this.campaignLink.message,
          code: this.campaignLink.code,
          deleteType: 'add_one',
        })

        const link = `${process.env.VUE_APP_SHOOTER_LINK}/${this.campaignLink.code}`
        const obj = {
          chat_text: this.campaignLink.message,
          link,
          code: this.campaignLink.code,
          name: this.campaignLink.name,
        }

        // https://api.whatsapp.com/send/?phone=628119885323&text=%23TLM1-1079%20Halo%20The%20Leaf%20Mansion!%0A%0ASaya%20tertarik%20dengan%20perumahan%20ini.%20Boleh%20informasikan%20detail%20terkait%20perumahan%20ini%3F%20Terima%20kasih%20%F0%9F%98%8A&app_absent=00
        this.campaignData.link.push(obj)
        this.campaignLink = {
          name: '',
          code: '',
          message: '',
        }
        this.closeDialogLink()
      } else if (
        this.dialogEditCampaign === true &&
        this.campaignLink.code &&
        this.campaignLink.name &&
        this.campaignLink.message &&
        this.editArrLengthZero === true
      ) {
        //console.log('masukd 2')

        // //console.log(this.campaignData, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
        this.isModify = true
        const res = await this.$store.dispatch('link/updateCampaignLink', {
          sub_id: this.user.sub_id,
          phone_number: this.campaignData.selectedInstance.value,
          instance: this.campaignData.selectedInstance.instance,
          campaignId: this.campaignId,
          campaign_name: this.campaignData.campaignName,
          code_name: this.campaignLink.name,
          chat_text: this.campaignLink.message,
          code: this.campaignLink.code,
          deleteType: 'add_one',
        })
        //console.log(res)

        const link = `${process.env.VUE_APP_SHOOTER_LINK}/${this.campaignLink.code}`
        const obj = {
          chat_text: this.campaignLink.message,
          link,
          code: this.campaignLink.code,
          name: this.campaignLink.name,
        }

        // https://api.whatsapp.com/send/?phone=628119885323&text=%23TLM1-1079%20Halo%20The%20Leaf%20Mansion!%0A%0ASaya%20tertarik%20dengan%20perumahan%20ini.%20Boleh%20informasikan%20detail%20terkait%20perumahan%20ini%3F%20Terima%20kasih%20%F0%9F%98%8A&app_absent=00
        this.campaignData.link.push(obj)
        this.campaignLink = {
          name: '',
          code: '',
          message: '',
        }
        this.closeDialogLink()
      }
      const result = await this.$store.dispatch('link/fetchListCampaign', { sub_id: this.user.sub_id })

      this.campaignList = result.data
    },
    closeDialogEditLink() {
      this.dialogEditCampaignLink = false
    },
    closeDialogLink() {
      this.dialogAddCampaignLink = false
    },
    createLink() {
      this.campaignData = {
        selectedInstance: null,
        campaignName: '',
        link: [],
      }
      this.$store.dispatch('link/saveCampaignId', this.$nanoid())
      this.dialogAddCampaign = true
    },
    editCampaign(data) {
      //console.log(data, 'ini edit data')

      // //console.log('🚀 ~ file: index.vue ~ line 2094 ~ editCampaign ~ data', data, this.instances)
      this.campaignData.selectedInstance = this.instances.find(el => el.value === data.phone_number)
      this.campaignData.campaignName = data.campaign_name
      const link = []
      data.campaign_tracker.forEach(el => {
        link.push({
          name: el.code_name,
          message: el.chat_text,
          chat_text: el.chat_text,
          code: el.code,
          link: this.campaignData.selectedInstance ? `${process.env.VUE_APP_SHOOTER_LINK}/${el.code}` : null,
        })
      })
      this.campaignData.link = link
      this.$store.dispatch('link/saveCampaignId', data.contact_list_id)

      //console.log(this.campaignData, 'masukkkkkkkkk', data)
      this.dialogEditCampaign = true
    },
    closeDialog() {
      this.dialogAddCampaign = false
      this.dialogEditCampaign = false
      this.campaignData = {
        selectedInstance: null,
        campaignName: '',
        link: [],
      }
    },
    async saveCampaign() {
      if (this.dialogEditCampaign === true && this.campaignData.link.length === 0 && this.editArrLengthZero === true) {
        this.errMessage = 'Data All Deleted'
        this.showSnackbarErr = true
        this.dialogEditCampaign = false
        const refecth = await this.$store.dispatch('link/fetchListCampaign', { sub_id: this.user.sub_id })
        this.campaignList = refecth.data
        this.campaignData.selectedInstance = null
      }
      if (this.dialogEditCampaign === true && this.campaignData.link.length !== 0 && this.editArrLengthZero === true) {
        //console.log('disini...........')
        const arr = []
        this.campaignData.link.forEach(el => {
          arr.push({
            code_name: el.name,
            chat_text: el.chat_text,
            code: el.code,
          })
        })
        await this.$store.dispatch('link/createCampaignLink', {
          sub_id: this.user.sub_id,
          phone_number: this.campaignData.selectedInstance.value,
          instance: this.campaignData.selectedInstance.instance,
          campaignId: this.campaignId,
          campaign_name: this.campaignData.campaignName,
          campaign_linkData: arr,
        })
        this.dialogAddCampaign = false
        this.editArrLengthZero = false
        const refecth = await this.$store.dispatch('link/fetchListCampaign', { sub_id: this.user.sub_id })

        this.campaignList = refecth.data
        this.isModify = false
      } else if (this.dialogAddCampaign === true && this.campaignData.link.length !== 0) {
        //console.log('disini..........2.')
        await this.$store.dispatch('link/updateCampaignLink', {
          sub_id: this.user.sub_id,
          phone_number: this.campaignData.selectedInstance.value,
          instance: this.campaignData.selectedInstance.instance,
          campaignId: this.campaignId,
          campaign_name: this.campaignData.campaignName,
          deleteType: 'none',
        })
        const refecth = await this.$store.dispatch('link/fetchListCampaign', { sub_id: this.user.sub_id })

        this.campaignList = refecth.data
        this.dialogEditCampaign = false
        this.dialogAddCampaign = false
        this.editArrLengthZero = false
        this.isModify = false
      } else if (this.dialogEditCampaign === true) {
        await this.$store.dispatch('link/updateCampaignLink', {
          sub_id: this.user.sub_id,
          phone_number: this.campaignData.selectedInstance.value,
          instance: this.campaignData.selectedInstance.instance,
          campaignId: this.campaignId,
          campaign_name: this.campaignData.campaignName,

          deleteType: 'none',
        })
        const refecth = await this.$store.dispatch('link/fetchListCampaign', { sub_id: this.user.sub_id })

        this.campaignList = refecth.data
        this.dialogEditCampaign = false
        this.dialogAddCampaign = false
        this.editArrLengthZero = false
        this.isModify = false
      } else if (this.dialogAddCampaign === true && this.campaignData.link.length === 0) {
        this.errMessage = 'Please add some Link for saving the Data'
        this.showSnackbarErr = true
      } else if (this.dialogAddCampaign === true && !this.campaignData.selectedInstance) {
        this.errMessage = 'Please Select The channel first'
        this.showSnackbarErr = true
      } else if (this.dialogAddCampaign === true && !this.campaignData.campaignName) {
        this.errMessage = 'Please add Add Campaign Name'
        this.showSnackbarErr = true
      }
    },
    updateCampaign() {
      this.dialogAddCampaign = false
      this.dialogEditCampaign = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-broadcast ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
::v-deep .v-label {
  font-size: 1em;
}
::v-deep .v-label--active {
  font-size: 1.25em;
  font-weight: bold;
}
.v-input .v-label {
  font-size: 1em !important;
}
.v-input .v-label--active {
  font-size: 1.25em !important;
  font-weight: bold !important;
}
.mobile-col {
  display: block;
}
.width-dialog {
  max-width: 680px;
}
.height-dialog {
  height: 450px;
  overflow-y: auto;
}
.order-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.mobile-col-show {
  display: none;
}
.dot {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 1100px) {
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}
.text-area-span {
  margin-top: -90px;
}

.mobile-span {
  display: inherit;
}
.margin-start4 {
  margin-left: 16px;
}
.wrapper {
  justify-content: center;
  padding: 20px 0;
  width: auto;
  text-align: center;
}
.description {
  text-align: center;
}
.map {
  width: 80%;
  height: 300px;
  display: inline-block;
  max-height: 100%;
  overflow: auto;
  border: 2px ridge silver;
  background-color: rgb(229, 227, 223);
}

@media screen and (max-width: 500px) {
  .description {
    display: none;
  }
}
@media (max-width: 600px) {
  .margin-start4 {
    margin-left: 0px;
  }
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .mobile-col-show {
    display: block;
  }
  .mobile-span {
    display: none;
  }
  .col-margin-top-mobile {
    margin-top: -30px;
  }
}

.additional-divider {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 32px;
  margin-right: 32px;
}
.additional-faq {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 48px;
  margin-right: 48px;
  padding-top: 10px;
}
@media (max-width: 960px) {
  .mobile-col {
    display: none;
  }
  .map {
    width: 100%;
    height: 300px;
    display: inline-block;
    max-height: 100%;
    overflow: auto;
    border: 2px ridge silver;
    background-color: rgb(229, 227, 223);
  }
  .additional-faq {
    border-left: 0px;
    border-top: 2px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color: #afd0ff;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
  }
}
</style>
